import React from 'react'
import Layout from "../components/layout"
import photoScandal from '../images/beatrice-press-conf.png'
import dogberry from '../images/dogberry-day-7.png'
import SEO from '../components/seo'

export default function MayorWelcomes() {
    return (
        <Layout>
            <SEO title="Local private eye seeks source of damning picture of Mayor’s daughter" image={photoScandal} />
            <h1>
                Local private eye seeks source of damning picture of Mayor’s daughter
            </h1>
            <img src={photoScandal} />
            <p>An investigation has launched into the person or persons responsible for the picture that appears to show Hero embracing an unidentified man behind the curtains of her bedroom window.</p> 

            <p>Dogberry, who bills himself as “Messina’s best and only” private investigator, has been hired by the Mayor’s niece, Beatrice, to investigate what she deems an “assault” on the reputation of her cousin.</p>

            <p>Little has been heard or seen of Hero since the picture’s release by The Scandalous Crier two days ago. Many questions remain, including how the individuals were able to obtain such a view into the Mayor’s home.</p>

            <p>Dogberry requests immediate assistance from the citizens of Messina. “I am investigating all leads,” he asserts. “Send me any pacific information you have.”</p>

            <p>Please message leads to 315-306-3536. Dogberry will follow-up directly with anyone who can provide specific information.</p>
            
            <img className="dogberry-ad" src={dogberry} />
        </Layout>
    )
}
